main > header {
  padding-top: 20px;
}
  
main > header p {
  display: inline-block;
  line-height: 1;
}
    
main > header p a {
  display: block;
}

@media (max-width: 900px) {
  main > header p a {
    width: 220px;
    overflow: hidden;
  }
}
      
main > header p a img {
  width: 262px;
  height: 30px;
  object-fit: contain;
}
  
main > header ul.user {
  float: right;
  margin: 0;
}
    
main > header ul.user li {
  position: relative;
  display: inline-block;
  padding: 0;
}
  
main > header ul.user li img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}

main > header nav {
  position: relative;
}
    
main > header nav ul {
  margin: 30px 0 50px 0;
  text-align: center;
}
  
main > header nav ul li {
  display: inline-block;
}
  
main > header nav ul li + li:before {
  content: '|'
}
          
main > header nav ul li a {
  display: inline-block;
  margin: 0 20px;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 300;
  color: inherit;
  text-decoration: none;
  line-height: 1.5em;
}

main > header nav ul li.bt {
  margin-left: 26px;
  font-weight: 500;
  text-transform: uppercase;
}
  
main > header nav ul li.active a {
  border-bottom: 1px solid
}      