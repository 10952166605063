.crud.list > .btn.add {
  float: right;
}
      
.crud.list > section {
  clear: both;
}
      
.crud.list > .list {
  margin: 20px 0 50px 0;
  padding: 20px;
  background: var(--background-content);
}
 
.crud.form > section > article {
  display: inline-block;
  width: 100%;
  margin: 20px 0 50px 0;
  padding: 20px;
  background: var(--background-content);
}

.crud.form .btn.btn-info {
  float: right;
} 