.dropbtn {
  background-color: white;
  padding: 16px 23px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  float: left;
  height: fit-content;
  width:100%;
}

.dropdown {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  width:100%;
}

.dropdown img {
  width:100%;
}

.dropdown-content {
  display:none;
  position: absolute;
  float: left;
  background-color: #fff;
  width: 70px;
  box-shadow: 0 2px 30px rgba(154,166,201,0.2);
  z-index: 1;
  left: 0px;
  bottom: 53px;
}

.dropdown-content img {
  width: 100%;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 23px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown *:focus {
  outline: none;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  height: 55px;
  background-color: #607ff2;
  height: fit-content;
}
   
