*,  
*:before,
*:after {
  box-sizing: border-box;
}

html, 
body {
  width: 100%;
  height: 100%;
  font-family: var(--font-major);
  color: var(--color-major);
  background: var(--background);
}
  
@media (max-width: 900px) {
  html, 
  body {
    padding: 0 5px;
  }
}

@media (max-width: 900px) {
  body {
    overflow-x: hidden;
  }
}

/* #root
	// overflow: hidden */

.center {
  position: relative;
  max-width: var(--container-width);
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .center {
    width: 1022px;
  }
}

@media (max-width: 1024px) {
  .center {
    width: 890px;
  }
}

@media (max-width: 900px) {
  .center {
    width: 100%;
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  font: 300 30px/1 var(--font-secondary);
}

h2 {
  font: 300 24px/1 var(--font-major);
  text-transform: uppercase;
}

h3 {
  font: 300 24px/1 var(--font-secondary);
}

p {
  font: 300 16px/1.5 var(--font-secondary);
}

@media (max-width: 1200px) {
  p {
    font-size: 15px;
  }
}

p.info {
  font-size: 12px;
}
    
a {
  cursor: pointer;
  color: var(--color-major);
}
  
strong {
  font-weight: bold;
}

.btn {
  position: relative;
  display: inline-block;
  height: 48px;
  padding: 0 20px;
  font: 600 15px/48px var(--font-major);
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border: 1px solid var(--border-color);
  user-select: none;
  border-radius: 24px;
}
 
.btn.loading {
  padding-right: 56px;
}
    
.btn.cancel .cross.delete,
.btn.delete .cross.delete, 
.btn.remove .cross.delete {
  left: auto;
  right: 20px;
  fill: white;
}

@media (max-width: 900px) {
  .btn.cancel .cross.delete,
  .btn.delete .cross.delete, 
  .btn.remove .cross.delete {
    padding: 0 10px;
    font-size: 12px;
  }
}

.btn.cancel.disabled,
.btn.delete.disabled, 
.btn.remove.disabled {
  cursor: not-allowed;
  color: var(--color-light) !important;
  border-color: var(--border-color) !important;
  background-color: var(--border-color) !important;
}

.btn.cancel.disabled .arrow path,
.btn.delete.disabled .arrow path, 
.btn.remove.disabled .arrow path {
  fill: var(--color-light);
}
     
.btn:not(.disabled):hover .arrow, 
.btn.hover:not(.disabled) .arrow {
  animation: slide 0.5s ease-in-out infinite;
}
      
.btn.loader {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%)
}

input[type='radio'],
input[type='checkbox'] {
  padding: 0;
}

input:not([type='checkbox'], [type='radio']) {
  border-radius: 0;
  -webkit-appearance: none;
}

p.checkbox {
  line-height: 1;
}
  
p.checkbox label {
  padding-left: 20px;
}
    
p.checkbox label input {
  margin: 0 5px 0 -20px;
  vertical-align: top;
}
      
p.checkbox label span {
  vertical-align: baseline;
}
      
p.checkbox a {
  color: var(--color-major);
}

label {
  position: relative;
  display: block;
  margin-bottom: 20px;
}
  
label.error input {
  border-color: red;
}

label span {
  display: block;
  margin-bottom: 10px;
  font: 300 14px/1 var(--font-major);
  color: var(--color-major);
}  
    
label span.help {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 0 0 10px;
  background: rgb(147, 147, 147);
  border-radius: 50%;
  font: normal 10px/15px var(--font-major);
  text-align: center;
  color: #fff;
  vertical-align: text-bottom;
}
      
label input, 
label textarea {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border: 1px solid var(--border-color);
  font: 300 18px/58px var(--font-major);
  color: var(--color-major);
}

@media (max-width: 900px) {
  label input, 
  label textarea {
    line-height: 18px;
  }
}
    
label textarea {
  height: 250px;
  padding: 20px;
  line-height: 24px;
  -webkit-appearance: none;
}


label input[type='checkbox'], input[type='radio'] {
  display: inline-block;
  width: auto;
  height: auto;
}

label input[type='checkbox'] + span,
label input[type='radio'] + span {
  display: inline-block;
}
   
@media (max-width: 900px) {
  label input[type='checkbox'] + span,
  label input[type='radio'] + span {
    width: calc(100% - 30px);
  }
}

select {
  width: 100%;
  padding: 0 20px;
  border: none;
  font: 300 18px/58px var(--font-major);
  color: var(--color-major);
  background: transparent;
}
  
@media (max-width: 900px) {
  select {
    -webkit-appearance: none;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 1.5em;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: var(--color-major) !important;
}
  
input.imokb {
  border: none;
  width: 100%;
  font: 300 18px/24px var(--font-major);
  background: transparent;
  width: 9rem;
  color: var(--color-major);
  text-align: center;
}

.errors {
  margin: 0 0 30px 0;
}

.errors li {
  position: relative;
  display: block;
  color: red;
}

.errors li::before {
  content: '●';
  display: inline-block;
  margin-right: 10px;
  color: red;
}