#login, 
#forgotpassword, 
#resetpassword {
  min-height: calc(100vh - 80px - 65px);
  padding-top: 50px;
}
  
#login > .center,
#forgotpassword > .center, 
#resetpassword > .center {
  width: 530px;
  padding: 0;
  background: var(--background-content);
}
    
#login > .center article,
#forgotpassword > .center article,
#resetpassword > .center article {
  padding: 30px;
}

#login h1,
#forgotpassword h1,
#resetpassword h1 {
  margin-bottom: 30px;
  text-align: center;
}
    
#login form::after,
#forgotpassword form::after,
#resetpassword form::after {
  content: '';
  display: block;
  clear: both;
}
  
#login form .btn,
#forgotpassword form .btn,
#resetpassword form .btn {
  position: absolute;
  transform: translateX(-50%);
  background-color: var(--background-content);
  left: 50%;
  border: 1px solid #EBECF0;
  width: 220px;
  font: 300 20px/25px var(--font-major);
  padding: 10px 20px;
  text-align: center;
  text-transform: none;
  color: #8B93A7;
}
    
#login form a,
#forgotpassword form a,
#resetpassword form a {
  color: var(--color-major);
}

#login form label div,
#forgotpassword form label div,
#resetpassword form label div {
  text-align: center;
  padding: 0.5rem;
  color: #5B6378;
  font: normal 20px/25px var(--font-major);
}
#login form label input,
#forgotpassword form label input,
#resetpassword form label input {
  border: 1px solid #BFC5D1;
  border-width: 0 0 1px 0;
}

