.center-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    width: 100%;
}

.imago-thumbnail {
    transform: rotate(180deg);
}