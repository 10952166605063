main > footer {
  padding: 30px 0;
  text-align: center;
  background: var(--color-secondary);
}

main > footer p {
  font: normal 12px/1 var(--font-major);
}
    
main > footer a {
  text-decoration: none;
  color: white;
}

main > footer .language {
  float: right;
}
