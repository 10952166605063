:root {
    --container-width: 1190px;

    --red: #E7433D;
    --red-dark: #c30016;
    --green: #5bbd66;
    --background: rgb(252, 253, 255);
    --background-content: #fff;
    --background-title: #EBECF0;
    --color-title: #29b787;
    --color-major: #5b6378;
    --color-secondary: #52afe1;
    --color-light: #939393;
    --border-color: #eeebeb;

    --font-major: 'Roboto';
    --font-secondary: 'Helvetica';

    --margin: 10px;

    /* Mobile */
    --mobile-container-width: 90vw;


    --gridCol: 90px;
    --gridColSmall: 76px;
    --gridColTablet: 65px;
    --gridMargin: 10px;
}