.svg-icon {
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.svg-icon > path,
.svg-icon > polygon,
.svg-icon > rect {
  fill: #8b93a7;
}

.svg-icon > circle {
  stroke: #8b93a7;
  stroke-width: 1;
}
